import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo";
import { Header } from "../components/header";
import { Grid, Flex, Spacer, Wrapper } from "../components/containers";
import { CardFlat } from "../components/preview";
import { Title } from "../components/type";
import { getPath } from "../../helpers";


export default ({data}) => {
    let content;
    let title;

    if (data.projectsByCategory) {
        content = data.projectsByCategory;
        title = data.category.title;
    } else {
        content = data.projectsBySubcategory;
        title = data.subcategory.title;
    }

    return (
    <Wrapper>
    <SEO title={data.title} />
    <Flex id="content" className="enter-left" p={4} justifyContent="center" position="relative">
        <Flex alignSelf={["start", "start", "start", "center"]} flexDirection={["column", "column", "row"]} justifyContent="center" width={["100%"]}>
            <Header maxHeight="250px" mr={[0, 0, 4, 6]} writingMode={["horizontal", "horizontal",  "vertical-rl"]}>
                <Title color="primary" fontSize={[3, 4, 5]} letterSpacing={3}>{title}</Title>
            </Header>
            <Spacer my={[3, 3, 0]}/>
            <Grid  
            gridGap={4} 
            gridAutoRows={[0, 1, 2, 3]}
            gridTemplateColumns={[0, 1, 2, 3]}
            justifyContent="center" 
            >
            {content.edges.map(edge => { 
                let image = edge.node.image;
                let href = getPath(edge.node.title);
                return <CardFlat key={edge.node.id} src={image.fixed.src} srcSet={image.fixed.srcSet} title={edge.node.title} href={`/${href}`}/>;
            })}
            </Grid>
        </Flex>
    </Flex>
    </Wrapper>
    );
}

export const query = graphql`
query($slug: String!, $isCategory: Boolean!) {
    projectsByCategory: allContentfulProject(filter: {category: {id: {eq: $slug}}}, sort: {fields: date, order: DESC}) @include(if: $isCategory) {
        edges {
            node {
                ...ProjectPreview
            }
        }
    }

    projectsBySubcategory: allContentfulProject(filter: {subcategories: {elemMatch: {id: {eq: $slug}}}}, sort: {fields: date, order: DESC}) @skip(if: $isCategory) {
        edges {
            node {
                ...ProjectPreview
            }
        }
    }

    category: contentfulCategory(id: {eq: $slug}) @include(if: $isCategory) {
        title
    }

    subcategory: contentfulSubcategory(id: {eq: $slug}) @skip(if: $isCategory) {
        title
    }
}
`;