import styled from 'styled-components';
import { layout, position, space, system } from 'styled-system';

export const Header = styled.header`
  ${position}
  ${space}
  ${layout}
  ${system({
    transform: true,
    writingMode: true
  })}
`;
