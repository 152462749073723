import React from 'react';
import * as Icon from 'react-feather';
import styled from 'styled-components';
import { Box, Flex } from './containers';
import { Link } from 'gatsby';
import { Heading } from './type';

const Card = styled(Box)`
    --curve-in-quadratic: cubic-bezier(0.55, 0.09, 0.68, 0.53);
    color: white;
    a {
        color: white;
    }
    img {
        height: 100%;
        width: 100%;
    }
    svg {
        color: ${props => props.theme.colors.primary}
    }
    .title-container {
        background: ${props => props.theme.colors.imageGradient};
        transition: opacity 300ms;
    }
    a:hover .mask, a:focus .mask {
        clip-path: polygon(0% 0, 100% 0%, 100% 65%, 55% 100%, 0 100%);
    }
`

const MaskWrapper = styled(Box)`
    filter: drop-shadow(10px 6px 8px rgba(159, 159, 159, .3));
`;

const Mask = styled(Flex)`
    clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%, 0 100%);
    transition: clip-path 200ms var(--curve-in-quadratic);
`;

const CardFlat = ({src, srcSet, title, href}) => 
<Card height="100%" width="100%" position="relative">
    <Link to={href}>
        <MaskWrapper height="100%" position="absolute" left={0} right={0} zIndex="1">
            <Mask className="mask" height="100%" position="relative">
                <Box position="absolute" zIndex="-1" bg="white" width="100%" height="100%">
                    <img css={{'object-fit': 'cover'}} alt="" src={src} srcSet={srcSet} />
                </Box>
                <Flex className={title && `title-container`} height="100%" p={3} width="100%">
                    <Heading fontFamily="display" fontSize={[3]}>{title}</Heading>
                </Flex>
            </Mask>
        </MaskWrapper>
        <Flex bottom="0" justifyContent="flex-end" p={[0, 0, 3]} position="absolute" width="100%" zIndex="0">
            <Box>
                <Icon.ArrowRight size={48}/>
            </Box>
        </Flex>
    </Link>
</Card>;

export {
    CardFlat,
}